<template>
    <div class="container-fluid">
        <Head></Head>
        <div class="row">
            <div class="col-lg-12" style="top: -220px;overflow: hidden;">
                <div class="mask"></div>
                <div class="back">
                    <div class="mucustomer">
                        <h1 class="mycustomer01">{{$t('MyCustomerMessage.MyCustomer1')}}</h1>
                        <h4 class="mycustomer01">{{$t('MyCustomerMessage.MyCustomer2')}}</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12" style="margin-top: -180px">
                <div class="container_make">


                    <div class="make_list">
                        <div class="list_img">
                            <img alt=""
                                 src="../../../assets/image/mycustomer/1.webp">
                        </div>
                        <div class="list_world">
                            <h4 class="mycustomer02">{{$t('MyCustomerMessage.MyCustomer3')}}</h4>
                        </div>
                    </div>

                    <div class="make_list">
                        <div class="list_img">
                            <img alt=""
                                 src="../../../assets/image/mycustomer/2.webp">
                        </div>
                        <div class="list_world">
                            <h4 class="mycustomer02">{{$t('MyCustomerMessage.MyCustomer4')}}</h4>
                        </div>
                    </div>

                    <div class="make_list">
                        <div class="list_img" >
                            <img alt="" style="margin-top: 35px"
                                 src="../../../assets/image/mycustomer/3.webp">
                        </div>
                        <div class="list_world">
                            <h4 class="mycustomer02">{{$t('MyCustomerMessage.MyCustomer5')}}</h4>
                        </div>
                    </div>

                    <div class="make_list">
                        <div class="list_img">
                            <img alt=""  style="margin-top: 25px"
                                 src="../../../assets/image/index/new.jpg">
                        </div>
                        <div class="list_world">
                            <h4 class="mycustomer02">{{$t('MyCustomerMessage.MyCustomer6')}}</h4>
                        </div>
                    </div>

                    <div class="make_list">
                        <div class="list_img">
                            <img alt=""
                                 src="../../../assets/image/mycustomer/5.webp">
                        </div>
                        <div class="list_world">
                            <h4  class="mycustomer03">{{$t('MyCustomerMessage.MyCustomer7')}}</h4>
                        </div>
                    </div>

                    <div class="make_list">
                        <div class="list_img">
                            <img alt=""
                                 src="../../../assets/image/mycustomer/6.webp">
                        </div>
                        <div class="list_world">
                            <h4  class="mycustomer03">{{$t('MyCustomerMessage.MyCustomer8')}}</h4>
                        </div>
                    </div>

                    <div class="make_list">
                        <div class="list_img">
                            <img alt="" style="margin-top: 25px"
                                 src="../../../assets/image/mycustomer/7.webp">
                        </div>
                        <div class="list_world">
                            <h4  class="mycustomer03">{{$t('MyCustomerMessage.MyCustomer9')}}</h4>
                        </div>
                    </div>

                    <div class="make_list">
                        <div class="list_img">
                            <img alt=""
                                 src="../../../assets/image/mycustomer/8.webp">
                        </div>
                        <div class="list_world">
                            <h4 class="mycustomer03">{{$t('MyCustomerMessage.MyCustomer10')}}</h4>
                        </div>
                    </div>
                    <div class="make_list">
                        <div class="list_img">
                            <img alt=""
                                 src="../../../assets/image/mycustomer/9.webp">
                        </div>
                        <div class="list_world">
                            <h4 class="mycustomer04">{{$t('MyCustomerMessage.MyCustomer11')}}</h4>
                        </div>
                    </div>

                    <div class="make_list">
                        <div class="list_img">
                            <img alt=""
                                 src="../../../assets/image/mycustomer/10.webp">
                        </div>
                        <div class="list_world">
                            <h4 class="mycustomer04">{{$t('MyCustomerMessage.MyCustomer12')}}</h4>
                        </div>
                    </div>

                    <div class="make_list">
                        <div class="list_img">
                            <img alt=""
                                 src="../../../assets/image/mycustomer/11.webp">
                        </div>
                        <div class="list_world">
                            <h4 class="mycustomer04">{{$t('MyCustomerMessage.MyCustomer13')}}</h4>
                        </div>
                    </div>

                    <div class="make_list">
                        <div class="list_img">
                            <img alt=""
                                 src="../../../assets/image/mycustomer/13.webp">
                        </div>
                        <div class="list_world">
                            <h4 class="mycustomer04">{{$t('MyCustomerMessage.MyCustomer14')}} </h4>
                        </div>
                    </div>


                </div>
            </div>
        </div>
        <Bottom style="margin-left: -35px;"></Bottom>
    </div>
</template>

<script>
    import {Bottom, Head} from "../commponents";

    export default {
        name: "MyCustomer",
         metaInfo: {
        meta: [{                 
            name: '暹诺回收官网,手机回收,笔记本回收,数码产品回收,企业回收',
            content: '上海暹诺环保科技有限公司 (简称: 暹诺环保©)是中国领先的环保解决方案提供商，为国内500多家商业和工业企业客户提供回收服务。我们投资开发电子垃圾解决方案，用以适应不断变化的世界。目前该领域不仅包括处理和回收，还包括确保敏感数据的安全的方案，以帮助我们的客户实现零废物的绿色目标。作为中国领先的电子废弃物管理服务供应商，我们的服务宗旨是在最大限度降低影响的同时，又能最大限度地提高资源价值，为所有利益相关者促进经济和环境的可持续发展。'
        }]
        },
        components: {Bottom, Head},
        created() {
            $(function () {
                $('.mycustomer01').animate({
                    opacity: '1',
                    top: '15px'
                }, 1000)


                $(window).scroll(function () {
                    var s = $(window).scrollTop();
                    console.log(s)
                    if (s > 350) {
                        $('.mycustomer02').animate({
                            opacity: '1',
                            top: '-15px'
                        }, 1000)
                    }
                    if (s > 650) {
                        $('.mycustomer03').animate({
                            opacity: '1',
                            top: '-15px'
                        }, 1000)
                    }
                    if (s > 800) {
                        $('.mycustomer04').animate({
                            opacity: '1',
                            top: '-15px'
                        }, 1000)
                    }

                });


            })

        }
    }


    $(function () {
        $('.mycustomer01').animate({
            opacity: '1',
            top: '15px'
        }, 1000)


        $(window).scroll(function () {
            var s = $(window).scrollTop();
            console.log(s)
            if (s > 350) {
                $('.mycustomer02').animate({
                    opacity: '1',
                    top: '-15px'
                }, 1000)
            }
            if (s > 650) {
                $('.mycustomer03').animate({
                    opacity: '1',
                    top: '-15px'
                }, 1000)
            }
            if (s > 800) {
                $('.mycustomer04').animate({
                    opacity: '1',
                    top: '-15px'
                }, 1000)
            }

        });


    })

</script>

<style scoped>
    .mycustomer01{
        opacity: 0;
        position: relative;
    }
    .mycustomer02{
        opacity: 0;
        position: relative;
    }
    .mycustomer03{
        opacity: 0;
        position: relative;
    }
    .mycustomer04{
        opacity: 0;
        position: relative;
    }


    .col-lg-12 .mask {
        position: absolute;
        height: 480px;

        background-color: black;

        opacity: 0.2;
        z-index: 1;
    }

    .back {
        height: 480px;

        margin-left: -15px;
        background: url("../../../assets/image/mycustomer/01.webp") no-repeat;
        background-size: 100% 100%;
    }

    .back .mucustomer {
        z-index: 99;
        height: 200px;
        width: 300px;
        position: relative;
        left: 50%;
        top: 50%;

        transform: translate(-50%, -50%);
        text-align: center;
    }

    .mucustomer h1 {
        color: #ffffff;
        font-weight: 800;
    }

    .mucustomer h4 {
        color: #ffffff;
        font-weight: 700;
        margin-top: 25px;
    }

    .col-lg-12 .container_make {
        height: 1000px;
        width: 80%;

        position: relative;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 50%;
        overflow: hidden;
    }

    .container_make .make_list {
        height: 329px;
        width: 25%;

        float: left;

    }

    .make_list .list_img {
        height: 178px;
        width: 100%;

        position: relative;
        text-align: center;
    }

    .list_img img {
        height: 130px;
        width: 130px;

    }

    .list_world {
        height: 151px;
        width: 100%;
        background-color: white;
        text-align: center;
    }

    .list_world h4 {
        color: black;
        line-height: 125px;
    }

</style>